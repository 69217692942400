import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faRedditAlien } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

Vue.component('font-awesome-icon', FontAwesomeIcon) // Register component globally
library.add(faRedditAlien)
library.add(faEnvelope)

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    values: {
      reddit: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fab', 'reddit-alien']
        }
      },
      email: {
        component: FontAwesomeIcon,
        props: {
          icon: ['fas', 'envelope']
        }
      }
    }
  }
})
