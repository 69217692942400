<template>
  <v-app>
    <v-main>
      <v-container fluid class="pa-0">
        <v-img src="https://giffiles.alphacoders.com/175/175882.gif" class="bg-image"></v-img>
      </v-container>
    </v-main>
    <v-footer app dark min-height="60">
      <v-row no-gutters justify="center">
        <v-col cols="12" class="text-center">
          <v-btn icon large :href="urlRedditDigimon" target="_blank" class="mr-5">
            <v-icon>$vuetify.icons.reddit</v-icon>
          </v-btn>
          <v-btn icon large :href="contactEmailMailto">
            <v-icon>$vuetify.icons.email</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="12" class="text-center">
          <v-divider class="my-1"></v-divider>
          <div class="text-caption">
            &copy; {{ new Date().getFullYear() }}
            <strong>
              <a :href="urlRedditAdmin" target="_blank" style="text-decoration:none;" class="white--text">
                Monsieur Moustache
              </a>
            </strong>
          </div>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  data: () => ({
    urlRedditDigimon: 'https://www.reddit.com/r/digimon/',
    urlRedditAdmin: 'https://www.reddit.com/u/m_moustache/',
    contactEmailMailto: 'mailto:awatar.wow@gmail.com'
  }),
};
</script>

<style scoped>
.bg-image {
  background-size:cover;
  left:0;
  top: 0;
  position:absolute;
  width:100%;
  height:100%;
}
</style>